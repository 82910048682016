import React from "react";
import { Navigate, Route } from "react-router";
import { all_routes } from "./all_routes";
import Home from "../home/home";
import Booking from "../pages/booking";

const routes = all_routes;

const publicRoutes = [
  {
    path: routes.home,
    element: <Home />,
    route: Route,
  },
  {
    path: routes.booking,
    element: <Booking />,
    route: Route,
  },
  // {
  //   path: routes.blogGrid,
  //   element: <BlogGrid />,
  //   route: Route,
  // },
  // {
  //   path: routes.blogGridSidebarRight,
  //   element: <BlogGridSidebarRight />,
  //   route: Route,
  // },
  // {
  //   path: routes.blogGridSidebarLeft,
  //   element: <BlogGridSidebarLeft />,
  //   route: Route,
  // },
  // {
  //   path: routes.blogList,
  //   element: <BlogList />,
  //   route: Route,
  // },
  // {
  //   path: routes.blogListSidebarRight,
  //   element: <BlogListSidebarRight />,
  //   route: Route,
  // },
  // {
  //   path: routes.blogListSidebarLeft,
  //   element: <BlogListSidebarLeft />,
  //   route: Route,
  // },
  // {
  //   path: routes.aboutUs,
  //   element: <AboutUs />,
  //   route: Route,
  // },

  // {
  //   path: routes.bookingCancelled,
  //   element: <BookingCancelled />,
  //   route: Route,
  // },
  // {
  //   path: routes.bookingCompleted,
  //   element: <BookingCompleted />,
  //   route: Route,
  // },
  // {
  //   path: routes.addCourt,
  //   element: <AddCourt />,
  //   route: Route,
  // },
  // {
  //   path: routes.allCourt,
  //   element: <AllCourt />,
  //   route: Route,
  // },
  // {
  //   path: routes.appointmentDetails,
  //   element: <AppointmentDetails />,
  //   route: Route,
  // },
  // {
  //   path: routes.blogCarousel,
  //   element: <BlogCarousel />,
  //   route: Route,
  // },
  // {
  //   path: routes.blogDetails,
  //   element: <BlogDetails />,
  //   route: Route,
  // },
  // {
  //   path: routes.blogDetailsSidebarLeft,
  //   element: <BlogDetailsSidebarLeft />,
  //   route: Route,
  // },
  // {
  //   path: routes.blogDetailsSidebarRight,
  //   element: <BlogDetailsSidebarRight />,
  //   route: Route,
  // },
  // {
  //   path: routes.coachDetail,
  //   element: <CoachDetail />,
  //   route: Route,
  // },
  // {
  //   path: routes.coachDetails,
  //   element: <CoachDetails />,
  //   route: Route,
  // },
  // {
  //   path: routes.coachEarning,
  //   element: <CoachEarning />,
  //   route: Route,
  // },
  // {
  //   path: routes.coachesGrid,
  //   element: <CoachesGrid />,
  //   route: Route,
  // },
  // {
  //   path: routes.coachesGridSidebar,
  //   element: <CoachesGridSidebar />,
  //   route: Route,
  // },
  // {
  //   path: routes.coachesList,
  //   element: <CoachesList />,
  //   route: Route,
  // },
  // {
  //   path: routes.coachesListSidebar,
  //   element: <CoachesListSidebar />,
  //   route: Route,
  // },
  // {
  //   path: routes.coachesMap,
  //   element: <CoachesMap />,
  //   route: Route,
  // },
  // {
  //   path: routes.coachRequest,
  //   element: <CoachRequest />,
  //   route: Route,
  // },
  // {
  //   path: routes.coachWallet,
  //   element: <CoachWallet />,
  //   route: Route,
  // },
  // {
  //   path: routes.coachTimeDate,
  //   element: <CoachTimeDate />,
  //   route: Route,
  // },
  // {
  //   path: routes.coachProfile,
  //   element: <CoachProfile />,
  //   route: Route,
  // },
  // {
  //   path: routes.coachPersonalInfo,
  //   element: <CoachPersonalInfo />,
  //   route: Route,
  // },
  // {
  //   path: routes.coachPayment,
  //   element: <CoachPayment />,
  //   route: Route,
  // },
  // {
  //   path: routes.coachOrederConfirm,
  //   element: <CoachOrederConfirm />,
  //   route: Route,
  // },
  // {
  //   path: routes.coachesMapSidebar,
  //   element: <CoachMapSideBar />,
  //   route: Route,
  // },
  // {
  //   path: routes.contactUs,
  //   element: <ContactUs />,
  //   route: Route,
  // },
  // {
  //   path: routes.activeCourt,
  //   element: <ActiveCourt />,
  //   route: Route,
  // },

  // {
  //   path: routes.cagecheckout,
  //   element: <CageCheckout />,
  //   route: Route,
  // },
  // {
  //   path: routes.cagedetails,
  //   element: <CageDetails />,
  //   route: Route,
  // },
  // {
  //   path: routes.cageordeconfirm,
  //   element: <CageOrderConfirm />,
  //   route: Route,
  // },
  // {
  //   path: routes.cagepersonalinfo,
  //   element: <CagePersonalInfo />,
  //   route: Route,
  // },

  // {
  //   path: routes.inactiveCourt,
  //   element: <InactiveCourt />,
  //   route: Route,
  // },
  // {
  //   path: routes.events,
  //   element: <Events />,
  //   route: Route,
  // },
  // {
  //   path: routes.eventdetails,
  //   element: <EventDetails />,
  //   route: Route,
  // },
  // {
  //   path: routes.inactiveCourt,
  //   element: <InactiveCourt />,
  //   route: Route,
  // },
  // {
  //   path: routes.earningCoaching,
  //   element: <EarningCoaching />,
  //   route: Route,
  // },
  // {
  //   path: routes.lessonTimeDate,
  //   element: <LessonTimedate />,
  //   route: Route,
  // },
  // {
  //   path: routes.lessonType,
  //   element: <LessonType />,
  //   route: Route,
  // },
  // {
  //   path: routes.listingGridSidebar,
  //   element: <ListingGridSidebar />,
  //   route: Route,
  // },
  // {
  //   path: routes.listingGrid,
  //   element: <ListingGrid />,
  //   route: Route,
  // },
  // {
  //   path: routes.listingList,
  //   element: <ListingList />,
  //   route: Route,
  // },
  // {
  //   path: routes.listingListSidebar,
  //   element: <ListingListSidebar />,
  //   route: Route,
  // },
  // {
  //   path: routes.listingMap,
  //   element: <ListingMap />,
  //   route: Route,
  // },
  // {
  //   path: routes.listingMapSidebar,
  //   element: <ListingMapSidebar />,
  //   route: Route,
  // },
  // {
  //   path: routes.userComplete,
  //   element: <UserComplete />,
  //   route: Route,
  // },
  // {
  //   path: routes.userDashboardProfiles,
  //   element: <UserDashboardProfiles />,
  //   route: Route,
  // },
  // {
  //   path: routes.userDashboard,
  //   element: <UserDashboard />,
  //   route: Route,
  // },
  // {
  //   path: routes.userInvoice,
  //   element: <UserInvoice />,
  //   route: Route,
  // },
  // {
  //   path: routes.coachDashboard,
  //   element: <CoachDashboard />,
  //   route: Route,
  // },
  // {
  //   path: routes.serviceDetail,
  //   element: <ServiceDetail />,
  //   route: Route,
  // },
  // {
  //   path: routes.services,
  //   element: <Services />,
  //   route: Route,
  // },
  // {
  //   path: routes.settingLesson,
  //   element: <SettingLesson />,
  //   route: Route,
  // },
  // {
  //   path: routes.settingPassword,
  //   element: <SettingPassword />,
  //   route: Route,
  // },
  // {
  //   path: routes.settingAvalibilty,
  //   element: <SettingAvailability />,
  //   route: Route,
  // },
  // {
  //   path: routes.termsCondition,
  //   element: <TermsCondition />,
  //   route: Route,
  // },
  // {
  //   path: routes.profileOtherSetting,
  //   element: <ProfileOtherSetting />,
  //   route: Route,
  // },
  // {
  //   path: routes.privacyPolicy,
  //   element: <PrivacyPolicy />,
  //   route: Route,
  // },
  // {
  //   path: routes.pricing,
  //   element: <Pricing />,
  //   route: Route,
  // },
  // {
  //   path: routes.ourTeams,
  //   element: <OurTeams />,
  //   route: Route,
  // },
  // {
  //   path: routes.myProfile,
  //   element: <MyProfile />,
  //   route: Route,
  // },
  // {
  //   path: routes.testimonials,
  //   element: <Testimonials />,
  //   route: Route,
  // },
  // {
  //   path: routes.testimonialsCarousel,
  //   element: <TestimonialsCarousel />,
  //   route: Route,
  // },
  // {
  //   path: routes.userBookings,
  //   element: <UserBookings />,
  //   route: Route,
  // },
  // {
  //   path: routes.coachBooking,
  //   element: <CoachBooking />,
  //   route: Route,
  // },
  // {
  //   path: routes.coachChat,
  //   element: <CoachChat />,
  //   route: Route,
  // },
  // {
  //   path: routes.faq,
  //   element: <Faq />,
  //   route: Route,
  // },

  // {
  //   path: routes.gallery,
  //   element: <Gallery />,
  //   route: Route,
  // },
  // {
  //   path: routes.invoice,
  //   element: <Invoice />,
  //   route: Route,
  // },
  // {
  //   path: routes.lessonOrderConfirm,
  //   element: <LessonOrderConfirm />,
  //   route: Route,
  // },
  // {
  //   path: routes.lessonPayment,
  //   element: <LessonPayment />,
  //   route: Route,
  // },
  // {
  //   path: routes.lessonPersonalinfo,
  //   element: <LessonPersonalinfo />,
  //   route: Route,
  // },
  // {
  //   path: routes.userChat,
  //   element: <UserChat />,
  //   route: Route,
  // },
  // {
  //   path: routes.userCoaches,
  //   element: <UserCoaches />,
  //   route: Route,
  // },
  // {
  //   path: routes.userProfile,
  //   element: <UserProfile />,
  //   route: Route,
  // },
  // {
  //   path: routes.userSettingPassword,
  //   element: <UserSettingPassword />,
  //   route: Route,
  // },
  // {
  //   path: routes.userProfileOtherSetting,
  //   element: <UserProfileOtherSetting />,
  //   route: Route,
  // },
  // {
  //   path: routes.userCancelled,
  //   element: <UserCancelled />,
  //   route: Route,
  // },
  // {
  //   path: routes.userWallet,
  //   element: <UserWallet />,
  //   route: Route,
  // },
  // {
  //   path: routes.venueDetails,
  //   element: <VenueDetails />,
  //   route: Route,
  // },
  // {
  //   path: routes.userOngoing,
  //   element: <UserOngoing />,
  //   route: Route,
  // },
  // {
  //   path: routes.changePassword,
  //   element: <ChangePassword />,
  //   route: Route,
  // },

  {
    path: "/",
    name: "Root",
    element: <Navigate to="/index" />,
    route: Route,
  },
  // {
  //   path: "*",
  //   name: "NotFound",
  //   element: <Navigate to="/index" />,
  //   route: Route,
  // },
];

const withoutHeaderRoutes = [
  // {
  //   path: routes.comingSoon,
  //   element: <ComingSoon />,
  //   route: Route,
  // },
  // {
  //   path: routes.error404,
  //   element: <Error404 />,
  //   route: Route,
  // },
  // {
  //   path: routes.register,
  //   element: <Signin />,
  //   route: Route,
  // },
  // {
  //   path: routes.login,
  //   element: <Login />,
  //   route: Route,
  // },
  // {
  //   path: routes.maintenance,
  //   element: <Maintenance />,
  //   route: Route,
  // },
  {
    path: routes.home,
    element: <Home />,
    route: Route,
  },
];
export { publicRoutes, withoutHeaderRoutes };
