import React, { useEffect, useState } from "react";
import axios, { all } from "axios";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import ImageWithBasePath from "../../core/data/img/ImageWithBasePath";

const GET_TIME_ROUTE = "https://padelapi.tbstaging.dev/api/get-schedules/";
const SEND_TIME_ROUTE = "https://padelapi.tbstaging.dev/api/book-court";

interface TimeObject {
  time: string;
  is_free: boolean;
  regular_price: string;
  first_additional_time: string;
  second_additional_time: string;
  court_id: number;
}

interface Reservation {
  time_from: string;
  court_id: number;
  date: string;
  price: string;
}

interface BookingFormData {
  name: string;
  email: string;
  phone: string;
  reservation: Reservation[];
  date: string;
}

const formatDate = (date: any) => {
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
};

const Booking = () => {
  const todayDate = new Date();
  const [timeSheets, setTimeSheets] = useState<any>({});
  const [date, setDate] = useState<any>(formatDate(todayDate));
  const [formData, setFormData] = useState<BookingFormData | null>(null);
  const [reservations, setReservations] = useState<Reservation[]>([]);
  const [selectedCourtID, setSelectedCourtID] = useState<number | null>(null);
  const [submitModalStatus, setSubmitModalStatus] = useState<boolean>(false);
  const [bookingPrice, setBookingPrice] = useState<string>("");

  const nextDays = () => {
    const year = todayDate.getFullYear();
    const month = todayDate.getMonth();
    const date = todayDate.getDate();
    const dates = [];

    for (let i = 0; i < 20; i++) {
      const day = new Date(year, month, date + i);

      const dayIndex = day.getDay();
      const days = [
        "Nedelja",
        "Ponedeljak",
        "Utorak",
        "Sreda",
        "Cetvrtak",
        "Petak",
        "Subota",
      ];
      const dateToAddObject = {
        displayDay: days[dayIndex],
        requestDate: formatDate(day),
      };
      dates.push(dateToAddObject);
    }
    return dates;
  };

  const handleBookingTime = (dateToBook: TimeObject, allTimesForCourt: any) => {
    // Check if the clicked element is free
    if (!dateToBook.is_free) {
      return; // Do nothing if is_free is false
    }

    const isAlreadyReserved = reservations.some(
      (item) => item.time_from === dateToBook.time
    );

    if (isAlreadyReserved) {
      // Remove logic as before
      const reservationIndex = reservations.findIndex(
        (item) => item.time_from === dateToBook.time
      );

      if (
        reservationIndex === 0 ||
        reservationIndex === reservations.length - 1
      ) {
        const reservationArray = reservations.filter(
          (item) => item.time_from !== dateToBook.time
        );
        const updatedPrice = reservationArray.reduce(
          (acc, item) => acc + Number(item.price),
          0
        );

        setReservations(reservationArray);
        setBookingPrice(updatedPrice.toString());

        if (reservationArray.length === 0) {
          setSelectedCourtID(null);
        }
      }
    } else {
      // Add logic with updated pricing conditions
      const reservationArray = [...reservations];
      let totalPrice = reservationArray.reduce(
        (acc, item) => acc + Number(item.price),
        0
      );

      if (reservationArray.length < 2) {
        const reservationObject: Reservation = {
          time_from: dateToBook.time,
          court_id: dateToBook.court_id,
          date: date,
          price: dateToBook.regular_price,
        };
        reservationArray.push(reservationObject);
        totalPrice += Number(dateToBook.regular_price);

        if (reservationArray.length === 1) {
          const nextSlot =
            allTimesForCourt[allTimesForCourt.indexOf(dateToBook) + 1];
          if (nextSlot) {
            const nextReservationObject: Reservation = {
              time_from: nextSlot.time,
              court_id: nextSlot.court_id,
              date: date,
              price: nextSlot.regular_price,
            };
            reservationArray.push(nextReservationObject);
            totalPrice += Number(nextSlot.regular_price);
          }
        }
      } else {
        // Check if the clicked time is the next available slot
        const lastReservation = reservationArray[reservationArray.length - 1];
        const lastTimeIndex = allTimesForCourt.findIndex(
          (slot) => slot.time === lastReservation.time_from
        );
        const nextTime = allTimesForCourt[lastTimeIndex + 1];

        if (nextTime && nextTime.time === dateToBook.time) {
          const indexInAdditionalSlots = reservationArray.length - 2;
          const priceToAdd =
            indexInAdditionalSlots % 2 === 0
              ? dateToBook.first_additional_time
              : dateToBook.second_additional_time;

          const reservationObject: Reservation = {
            time_from: dateToBook.time,
            court_id: dateToBook.court_id,
            date: date,
            price: priceToAdd,
          };
          reservationArray.push(reservationObject);
          totalPrice += Number(priceToAdd);
        }
      }

      setReservations(reservationArray);
      setBookingPrice(totalPrice.toString());

      if (selectedCourtID !== dateToBook.court_id) {
        setSelectedCourtID(dateToBook.court_id);
      }
    }
  };

  console.log(reservations);

  const disableIntermediarySlots = (schedules) => {
    Object.keys(schedules).forEach((court) => {
      const bookedSlots = [];
      const courtSlots = schedules[court][0]; // Get the slots for the current court

      // Collect booked slots for the current court
      courtSlots.forEach((slot) => {
        if (!slot.is_free) {
          bookedSlots.push(slot.time);
        }
      });

      // Sort booked slots to find gaps
      bookedSlots.sort(
        (a, b) => new Date(`1970-01-01T${a}`) - new Date(`1970-01-01T${b}`)
      );

      // Disable intermediary slots for the current court
      schedules[court][0] = courtSlots.map((slot) => {
        const slotTime = slot.time;
        const isBetweenBooked = bookedSlots.some((bookedTime, index) => {
          if (index === 0) return false; // Skip the first booked time
          const prevBookedTime = bookedSlots[index - 1];
          return (
            new Date(`1970-01-01T${prevBookedTime}`) <
              new Date(`1970-01-01T${slotTime}`) &&
            new Date(`1970-01-01T${slotTime}`) <
              new Date(`1970-01-01T${bookedTime}`)
          );
        });
        return { ...slot, is_free: !isBetweenBooked && slot.is_free };
      });
    });
  };

  const handleFormSubmit = () => {
    const formDataToSend = {
      ...formData,
      reservations: [...reservations],
      date: date,
    };
    axios
      .post("https://padelapi.tbstaging.dev/api/book-court", formDataToSend)
      .then((resp) => {
        console.log(resp);
        getTimeSheets();
        setReservations([]);
        setSubmitModalStatus(false);
      })
      .catch((error) => {
        error;
      });
  };

  const handleSubmitChange = (value, propertyToChange) => {
    setFormData((prevData) => ({
      ...prevData,
      [propertyToChange]: value,
    }));
  };

  const getTimeSheets = () => {
    axios
      .get(`${GET_TIME_ROUTE}${date}`)
      .then((response) => {
        const resp = response.data.data[0];
        console.log(resp);
        setTimeSheets(resp);
        disableIntermediarySlots(resp);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getTimeSheets();
  }, [date]);

  return (
    <>
      <div className=" mb-5">
        <div>
          <Swiper
            navigation
            pagination={{ clickable: false }}
            scrollbar={{ draggable: true }}
            style={{ padding: "50px" }}
            breakpoints={{
              320: {
                slidesPerView: 3,
              },
              390: {
                slidesPerView: 2,
              },
              993: {
                slidesPerView: 6,
              },
              1400: {
                slidesPerView: 8,
              },
            }}
          >
            {nextDays().map((dateElement, index) => (
              <SwiperSlide key={index}>
                <div
                  className={`card shadow-lg ${date === dateElement.requestDate ? "text-bg-primary" : null}`}
                  style={{ width: "12rem", cursor: "pointer" }}
                  onClick={() => setDate(dateElement.requestDate)}
                >
                  <div className="card-body">
                    <h5 className="card-title">{dateElement.displayDay}</h5>
                    <p className="card-text">{dateElement.requestDate}</p>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      <div className="container">
        {Object.entries(timeSheets).map(([key, nestedArray]) => (
          <div key={key} className="d-flex flex-row mb-5 court-wrapper">
            <div style={{ textAlign: "center" }}>
              <ImageWithBasePath
                src={
                  key === "Teren 4"
                    ? "assets/img/court-5.png"
                    : "assets/img/court-1.png"
                }
                className="img-fluid"
                alt="Event"
                style={{ width: "100px", height: "50px" }}
              />
              <h4>{key}</h4>
            </div>
            <Swiper
              // slidesPerView={15}
              navigation
              pagination={{ clickable: false }}
              scrollbar={{ draggable: true }}
              style={{
                padding: "0 50px 0 50px",
                width: "90%",
                marginBottom: "30px",
                cursor: "pointer",
              }}
              breakpoints={{
                320: {
                  slidesPerView: 12,
                  direction: "vertical",
                },
                993: {
                  slidesPerView: 10,
                },
                1400: {
                  slidesPerView: 15,
                },
              }}
            >
              {nestedArray[0].map((item, index) => (
                <SwiperSlide key={index}>
                  <span
                    className={`badge text-bg-light p-2 ${!item.is_free ? "opacity-25" : null} ${reservations.find((element) => element.time_from === item.time && element.court_id === item.court_id) ? "bg-primary text-white" : null}`}
                    onClick={() =>
                      selectedCourtID === null ||
                      selectedCourtID === item.court_id
                        ? handleBookingTime(item, nestedArray[0])
                        : null
                    }
                  >
                    {item.time}
                  </span>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        ))}
      </div>
      <div className="container d-flex justify-center align-center mb-5">
        <button
          className="nav-link btn btn-primary"
          onClick={() => setSubmitModalStatus(true)}
          disabled={reservations.length < 2}
        >
          <span>
            <i className="feather-check-circle" />
          </span>
          Book Now {bookingPrice}
        </button>
      </div>
      {submitModalStatus && (
        <div
          className={`modal fade ${submitModalStatus && "show d-block"}`}
          id="exampleModal"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Book your time
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setSubmitModalStatus(false)}
                ></button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="mb-3">
                    <label className="form-label">Email address</label>
                    <input
                      type="email"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      onChange={(event) =>
                        handleSubmitChange(event.target.value, "email")
                      }
                    />
                    <div id="emailHelp" className="form-text">
                      Well never share your email with anyone else.
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputPassword1"
                      onChange={(event) =>
                        handleSubmitChange(event.target.value, "name")
                      }
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Phone</label>
                    <input
                      type="tel"
                      className="form-control"
                      id="exampleInputPassword1"
                      onChange={(event) =>
                        handleSubmitChange(event.target.value, "phone")
                      }
                    />
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setSubmitModalStatus(false)}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleFormSubmit}
                  disabled={
                    !formData?.email || !formData?.name || !formData?.phone
                  }
                >
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Booking;
